import { useAtom } from "jotai";
import { formdataAtom, isUpdatingAtom, sharedErrorsAtom } from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const useUpdateEmployment = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const [, setSharedErrors] = useAtom(sharedErrorsAtom);

  const updateEmploymentToDatabase = async () => {
    //console.log("Updating employment data");
    Swal.fire({
      title: "Please confirm your answers",
      text: "You will not be able to change it after this. Continue?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const email = formdata.email;

        const fd = {
          email: email,
          employment: {
            employment_status: formdata.employment_status,
            unemployment_duration: formdata.unemployment_duration,
            work_experience_years: formdata.work_experience_years,
            service_completion_date: formdata.service_completion_date,
            reason_for_leaving: formdata.reason_for_leaving,
            exit_timeline: formdata.exit_timeline,
            job_urgency: formdata.job_urgency || 0,
          },
        };

        const apiUrl =
          process.env.REACT_APP_BASE_URL + `/application/employment`;
        // const apiUrl = `https://credentials.naomilucas.xyz/api/bootcamp/update/${email}`;

        try {
          const res = await axios.post(apiUrl, fd);
          console.log(res);
          router("/apply/other");
        } catch (error) {
          console.log("ERRR::", error);

          const msg =
            error?.message ||
            error?.response?.data?.message ||
            "An error occured. Try again";

          setSharedErrors((prev) => [...prev, msg]);

          const errors = error?.response?.data?.data?.error;

          if (errors) {
            Object.keys(errors).forEach((key) => {
              const messages = errors[key]; // Array of messages
              messages.forEach((message) => {
                console.log(message);
                setSharedErrors((prev) => [...prev, message]);
              });
            });
          }
          return Swal.close();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    // .then((result) => {
    //   if (result.isConfirmed) {
    //     Swal.fire({
    //       title: "Almost there!",
    //       text: "You are almost done with your application",
    //       icon: "success",
    //       confirmButtonText: "Continue",
    //       confirmButtonColor: "#ECA239",
    //       preConfirm: async () => {
    //         router("/apply/other");
    //       },
    //     });
    //   }
    // });
  };

  return { updateEmploymentToDatabase };
};

// import { useAtom } from "jotai";
// import { formdataAtom, isUpdatingAtom } from "../lib/atom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { useState } from "react";

// export const useUpdateEmployment = () => {
//   const router = useNavigate();
//   const [formdata] = useAtom(formdataAtom);

//   const updateEmploymentToDatabase = async () => {
//     Swal.fire({
//       title: "Please confirm your answers",
//       text: "You will not be able to change it after this. Continue?",
//       icon: "info",
//       showCancelButton: true,
//       confirmButtonColor: "#ECA239",
//       cancelButtonColor: "#d2d2d7",
//       confirmButtonText: "Yes, continue",
//       cancelButtonText: "Edit",
//       showLoaderOnConfirm: true,
//       preConfirm: async () => {
//         console.log("Form data would be sent to backend:", formdata);

//         // Simulate successful API call
//         // Comment out axios.post and replace with simulated success message
//         // const res = await axios.post(apiUrl, fd);
//         // console.log(res);

//         return true; // Simulate success
//       },
//       allowOutsideClick: () => !Swal.isLoading(),
//     }).then((result) => {
//       if (result.isConfirmed) {
//         Swal.fire({
//           title: "Almost there!",
//           text: "You are almost done with your application",
//           icon: "success",
//           confirmButtonText: "Continue",
//           confirmButtonColor: "#ECA239",
//           preConfirm: async () => {
//             router("/apply/other");
//           },
//         });
//       }
//     });
//   };

//   return { updateEmploymentToDatabase };
// };
