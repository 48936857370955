import React from 'react';
import 'boxicons'

const Bootcamp = () => {
    return (
        <section id="services" className="services">
            <div className="container">

                <div className="section-title">
                    <h2 className="text-center text-black font-family-Raleway">
            <span>
              <svg style={{ marginTop: '-40px' }} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.0705 20.9499C9.0984 20.2356 6.23627 20.2823 3.22162 20.1967C2.63287 20.1801 2.1434 20.632 2.12567 21.2057C2.10794 21.7796 2.57256 22.2586 3.16131 22.2756C6.01991 22.3567 8.74017 22.2912 11.5633 22.9691C12.1343 23.1064 12.7124 22.765 12.8507 22.2077C12.9926 21.6507 12.6415 21.0868 12.0705 20.9499Z" fill="#161B23" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.8309 14.8815C13.2983 10.0355 7.46761 5.46945 1.58016 2.27801C1.0659 1.9997 0.416831 2.18098 0.133099 2.68285C-0.150634 3.18471 0.0338085 3.81828 0.548072 4.09659C6.24045 7.18266 11.8797 11.5986 16.2598 16.2846C16.657 16.7081 17.3309 16.7375 17.7636 16.3504C18.1963 15.9633 18.2282 15.3051 17.8309 14.8815Z" fill="#161B23" />
                <path fillRule="evenodd" clipRule="evenodd" d="M22.7834 13.6539C23.5318 9.22171 22.255 4.65326 20.4498 0.62307C20.2121 0.0972884 19.5844 -0.142562 19.0488 0.0872304C18.5097 0.317368 18.265 0.93119 18.4991 1.45698C20.1447 5.12741 21.3648 9.27855 20.6838 13.3153C20.5881 13.8817 20.9818 14.4172 21.5599 14.5104C22.138 14.604 22.6877 14.2199 22.7834 13.6539Z" fill="#161B23" />
              </svg>
            </span>
                        THE BOOTCAMP IS FOR YOU <br/> IF YOU ARE:
                    </h2>
                </div>

                <div className="row justify-content-center">
   
   
    <div className="col-lg-6 mt-4">
        <div className="d-flex h-100">
            <div className="p-4" style={{ background: '#EBECEE', borderRadius: '8px' }}>
                <div className="d-flex align-items-center mb-4">
                    <div className="rounded-circle rounded" style={{ background: '#BBBDC0',  position: 'relative'}}>
                        <i style={{ fontSize: "30px" }} className='bx bx-right-top-arrow-circle text-black'></i>
                    </div>
                    <div className="ms-3">
                        <h3 style={{ color: '#161B23', fontSize: '24px', fontFamily: 'Raleway', fontWeight: 600, lineHeight: '28.80px' }}>A Beginner</h3>
                        <p style={{ color: '#252D3C', fontSize: '16px', fontFamily: 'Raleway', fontWeight: 500, lineHeight: '24px', wordWrap: 'break-word' }}>
                            You just finished a post-secondary institution and have little or no work experience.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <div className="col-lg-6 mt-4">
        <div className="d-flex h-100">
            <div className="p-4" style={{ background: '#EBECEE', borderRadius: '8px' }}>
                <div className="d-flex align-items-center mb-4">
                    <div className="rounded-circle rounded" style={{ background: '#BBBDC0',  position: 'relative'}}>
                        <i style={{ fontSize: "30px" }} className='bx bx-right-top-arrow-circle text-black'></i>
                    </div>
                    <div className="ms-3">
                        <h3 style={{ color: '#161B23', fontSize: '24px', fontFamily: 'Raleway', fontWeight: 600, lineHeight: '28.80px' }}>Unemployed</h3>
                        <p style={{ color: '#252D3C', fontSize: '16px', fontFamily: 'Raleway', fontWeight: 500, lineHeight: '24px', wordWrap: 'break-word' }}>
                            You are in desperate need of the job, preferably within the next 12 weeks.
                            </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col-lg-6 mt-4">
        <div className="d-flex h-100">
            <div className="p-4" style={{ background: '#EBECEE', borderRadius: '8px' }}>
                <div className="d-flex align-items-center mb-4">
                    <div className="rounded-circle rounded" style={{ background: '#BBBDC0',  position: 'relative'}}>
                        <i style={{ fontSize: "30px" }} className='bx bx-right-top-arrow-circle text-black'></i>
                    </div>
                    <div className="ms-3">
                        <h3 style={{ color: '#161B23', fontSize: '24px', fontFamily: 'Raleway', fontWeight: 600, lineHeight: '28.80px' }}>Confused About Your Next Move</h3>
                        <p style={{ color: '#252D3C', fontSize: '16px', fontFamily: 'Raleway', fontWeight: 500, lineHeight: '24px', wordWrap: 'break-word' }}>
                            Your lack of clarity is giving you a lot of anxiety. You are at a crossroad in your career. You know you can't remain where you are but you don't have a game plan and don't know what to do.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="col-lg-6 mt-4">
        <div className="d-flex h-100">
            <div className="p-4" style={{ background: '#EBECEE', borderRadius: '8px' }}>
                <div className="d-flex align-items-center mb-4">
                    <div className="rounded-circle rounded" style={{ background: '#BBBDC0', position: 'relative' }}>
                        <i style={{ fontSize: "30px" }} className='bx bx-right-top-arrow-circle text-black'></i>
                    </div>
                    <div className="ms-3">
                        <h3 style={{ color: '#161B23', fontSize: '24px', fontFamily: 'Raleway', fontWeight: 600, lineHeight: '28.80px' }}>Global Citizen</h3>
                        <p style={{ color: '#252D3C', fontSize: '16px', fontFamily: 'Raleway', fontWeight: 500, lineHeight: '24px', wordWrap: 'break-word' }}>
                            You are a young African living outside the continent. You have just relocated or graduated. You face very peculiar hurdles in the job market. You need clarity on how to find meaningful work, and the support of an active community of like minds.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


            </div>
        </section>
    );
};

export default Bootcamp;
