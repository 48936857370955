import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { formdataAtom } from "../../lib/atom";
import { useSendProgress } from "../../hooks/UseSendVideoProgressToBackend";

const SelectionVideo = () => {
  const [progress, setProgress] = useState(0);
  const [currQuestions, setCurrQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [questionAnswered, setQuestionAnswered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPlayedTime, setLastPlayedTime] = useState(0); // Track last valid time
  const [formdata, setFormdata] = useAtom(formdataAtom);

  const [questions, setQuestions] = useState([
    {
      question1: "The Employment Bootcamp is:",
      options1: [
        { label: "A retreat", value: "A" },
        { label: "A masterclass", value: "B" },
        { label: "A learning game", value: "C" },
        { label: "A seminar", value: "D" },
      ],
      question2: "What is our promise to you?",
      options2: [
        {
          label:
            "If you complete the bootcamp you will get automatic employment",
          value: "A",
        },
        { label: "We pay you to attend the bootcamp", value: "B" },
        {
          label:
            "If you are open to learning, unlearning and relearning, the version that awaits you on the other side, is a version you will not believe.",
          value: "C",
        },
        { label: "You get two free slots for attending", value: "D" },
      ],
      answers: {
        question1: "C",
        question2: "C",
      },
      time: 267,
      rewatchTime: 0,
    },
  ]);

  const playerRef = useRef(null);
  const selectedAnswerRef = useRef({});

  const router = useNavigate();
  const MySwal = withReactContent(Swal);
  const { sendVideoProgressToBackend } = useSendProgress();

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.currentTime = formdata.videoProgress || 0; // Resume video from saved time
    }
  }, [formdata.videoProgress]);

  const handleVideoProgress = (e) => {
    const playedSeconds = e.target.currentTime;
    setProgress((playedSeconds / e.target.duration) * 100);
    setLastPlayedTime(playedSeconds); // Update the last played time

    const nextQuestions = questions.filter(
      (q) =>
        playedSeconds >= q.time &&
        !q.shown &&
        !q.answeredCorrectly &&
        currQuestions.length === 0
    );

    if (nextQuestions.length) {
      setCurrQuestions(nextQuestions);
      setQuestions(
        questions.map((q) =>
          nextQuestions.includes(q) ? { ...q, shown: true } : q
        )
      );
      e.target.pause();
      showQuestionsPopup(nextQuestions);
    }
  };

  const handleVideoEnded = () => {
    setProgress(100);
    setFormdata((prevFormdata) => ({
      ...prevFormdata,
      videoProgress: 100,
      isVideoEnded: true,
    }));
    // sendVideoProgressToBackend();
  };

  const handleSeeking = (e) => {
    if (e.target.currentTime > lastPlayedTime) {
      e.target.currentTime = lastPlayedTime; // Prevent seeking forward
      MySwal.fire({
        title: "Sorry",
        text: "You can't skip through the video.",
        icon: "info",
        confirmButtonText: "OK",
        confirmButtonColor: "#ECA239",
      });
    }
  };

  const showQuestionsPopup = (questionsToShow) => {
    setSelectedAnswers({}); // Clear selected answers before the next
    MySwal.fire({
      title: "Answer the following questions",
      html: questionsToShow
        .map(
          (q, index) => `
        <div>
          <h5 style={font-weight: "bold"}>${q.question1}</h5>
          ${q.options1
            .map(
              (option) => `
            <div style="display: flex; margin: 10px 0;">
              <input type="radio" id="q1-${option.value}" name="question1" value="${option.value}" />
              <label for="q1-${option.value}" style="margin-left: 5px">${option.label}</label>
            </div>
          `
            )
            .join("")}
          <hr />
          <h5>${q.question2}</h5>
          ${q.options2
            .map(
              (option) => `
            <div style="display: flex; margin: 10px 0;">
              <input type="radio" id="q2-${option.value}" name="question2" value="${option.value}" />
              <label for="q2-${option.value}" style="margin-left: 5px">${option.label}</label>
            </div>
          `
            )
            .join("")}
        </div>
      `
        )
        .join(""),
      customClass: {
        htmlContainer: "text-start",
        title: "18px",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      confirmButtonColor: "#ECA239",
      cancelButtonText: "Skip",
      allowOutsideClick: false,
      allowEscapeKey: false,
      heightAuto: false,
      width: "60em",
      preConfirm: () => {
        const selected = {};
        ["question1", "question2"].forEach((q) => {
          const selectedOption = Swal.getPopup().querySelector(
            `input[name="${q}"]:checked`
          );
          if (selectedOption) {
            selected[q] = selectedOption.value;
          }
        });
        if (Object.keys(selected).length !== 2) {
          Swal.showValidationMessage("Please answer all questions or skip");
          return false;
        }
        return selected;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const selectedAnswers = result.value;
        questionsToShow.forEach((q) => {
          handleAnswerSubmit(
            selectedAnswers["question1"],
            selectedAnswers["question2"],
            q
          );
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleSkip();
      }
    });
  };

  const handleAnswerSubmit = (answer1, answer2, question) => {
    const isCorrect1 = answer1 === question.answers.question1;
    const isCorrect2 = answer2 === question.answers.question2;
    const isCorrect = isCorrect1 && isCorrect2;
    const updatedQuestions = questions.map((q) => {
      if (q === question) {
        return { ...q, answeredCorrectly: isCorrect, shown: false }; // Ensure shown is set to false
      }
      return q;
    });

    setQuestions(updatedQuestions); // Update the questions array with the modified question
    setQuestionAnswered(isCorrect);
    setCurrQuestions([]);

    if (isCorrect) {
      MySwal.fire({
        title: "Correct answers!",
        html: `
          <button id="continue-button" class="swal2-confirm swal2-styled">Continue</button>
        `,
        showConfirmButton: false,
        willOpen: () => {
          const continueButton = document.getElementById("continue-button");
          if (continueButton) {
            continueButton.onclick = () => {
              MySwal.close();
              if (playerRef.current) {
                playerRef.current.play(); // Play the video after closing the popup
              } else {
                console.error("Video player is not available");
              }
            };
          }
        },
      });
    } else {
      MySwal.fire({
        title: "Incorrect answers!",
        html: `
          <button id="retry-button" class="swal2-confirm swal2-styled">Retry</button>
          <button id="rewatch-button" class="swal2-cancel swal2-styled">Rewatch</button>
        `,
        showConfirmButton: false,
        showCancelButton: false,
        willOpen: () => {
          const retryButton = document.getElementById("retry-button");
          const rewatchButton = document.getElementById("rewatch-button");
          if (retryButton && rewatchButton) {
            retryButton.onclick = () => {
              MySwal.close();
              handleRetry(question);
            };
            rewatchButton.onclick = () => {
              MySwal.close();
              handleRewatch(question.rewatchTime, question);
            };
          }
        },
      });
    }
  };

  const handleSkip = () => {
    setCurrQuestions([]);
    setQuestionAnswered(true);
    playerRef.current.play();
  };

  const handleRetry = (question) => {
    setFormdata({ ...formdata, attemptCounter: formdata.attemptCounter++ });
    setQuestionAnswered(false);
    setCurrQuestions([question]);
    showQuestionsPopup([question]);
  };

  const handleRewatch = (time, question) => {
    setQuestions(
      questions.map((q) => (q === question ? { ...q, shown: false } : q))
    );
    playerRef.current.currentTime = time;
    playerRef.current.play();
  };

  const handleVideoError = () => {
    Swal.fire(
      "Error",
      "An error occurred while playing the video. Please try again later",
      "error"
    );
  };

  const videoPublicId = "welcome_hizoo7";
  const videoSrc = `https://res.cloudinary.com/dxjvh9voy/video/upload/v1713884654/${videoPublicId}.mp4`;
  const thumbnailSrc = `https://res.cloudinary.com/dxjvh9voy/video/upload/v1713884654/${videoPublicId}.jpg`;

  const handleNextSection = async () => {
    if (!formdata.isVideoEnded) {
      console.log(formdata); // Check if isVideoEnded is true
      Swal.fire("Notice", "Please watch video till the end");
      return;
    }
    setIsLoading(true);
    router("/apply/confirmation");
  };

  return (
    <div className="col-md-8 slide-col 100vh" id="selection-video">
      <h4 className="text-start text-uppercase fw-bold">Info Video</h4>
      <div>
        <video
          ref={playerRef}
          poster={thumbnailSrc}
          className="rounded-4"
          controls
          style={{ position: "relative", zIndex: 0, width: "100%" }}
          onTimeUpdate={handleVideoProgress}
          onSeeking={handleSeeking} // Add event listener for seeking
          onEnded={handleVideoEnded}
          onError={handleVideoError}
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="float-end pt-3">
        <button
          onClick={() => router(-1)}
          type="button"
          className="btn btn-secondary me-2"
        >
          Back
        </button>
        {isLoading ? (
          <button
            disabled
            type="button"
            className="btn btn-primary"
            id="btnLoading"
          >
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </button>
        ) : (
          <button
            type="button"
            onClick={handleNextSection}
            className="btn btn-primary"
            style={{
              backgroundColor: "#ECA239",
              color: "black",
              border: "1px solid #ECA239",
            }}
          >
            Continue
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectionVideo;