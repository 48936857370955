import { useAtom } from "jotai";
import { formdataAtom } from "../../lib/atom";

const TextArea = ({ label, name, value, onChangeFunc = () => {} }) => {
  const [formdata, setFormdata] = useAtom(formdataAtom);
  return (
    <>
      <label
        htmlFor={name + value}
        className="form-label"
        style={{ color: "black", fontWeight: "bold" }}
      >
        {label}
      </label>
      <textarea
        name={name}
        className="form-control"
        id={name + value}
        value={value}
        onChange={(e) => setFormdata({ ...formdata, [name]: e.target.value })}
      ></textarea>
    </>
  );
};

export default TextArea;
