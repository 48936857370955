import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { useAtom } from "jotai";
import { progressAtom } from "../lib/atom";
import { percentageAtom } from "../lib/atom";

const ApplyPage = ({ referralCode }) => {
  const [progress] = useAtom(progressAtom);
  const [percentage] = useAtom(percentageAtom);

  // console.log(":::::", referralCode);

  // console.log("Progress:", progress);
  // console.log("Percentage:", percentage);

  return (
    <>
      <div className="mt-5" id="applyContainer" style={{ color: "#212529" }}>
        <section id="applicationHeader" style={{ padding: "0px" }}>
          <div className="container">
            <div className="row mt-3 justify-content-center">
              <h2
                className="text-center text-uppercase fw-bolder"
                style={{ color: "#ECA239" }}
              >
                The Employment Bootcamp Application Form
              </h2>
            </div>
          </div>
        </section>
        <section id="applicationMain" style={{ padding: "0px" }}>
          <div className="container">
            <div
              className="row mt-3 justify-content-center slide-row"
              id="sliderRow"
            >
              <Outlet />
            </div>
          </div>
        </section>
        <section id="applicationFooter">
          <div className="container">
            <div className="row mt-3 justify-content-center">
              <div className="col-md-8">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: percentage + "%" }}
                    aria-valuenow="20"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <div className="text-end">
                  <span id="currentSectionDiv">{progress}</span> of 5
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ApplyPage;
