import React from 'react';
import logo from "../../assets/img/logo-black.png";

function Footer() {
    return (
        <footer id="footer">
            <section className="footer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-4 col-12 col text-center">
                            <img
                                src={logo}
                                className="img-fluid"
                                style={{ maxWidth: '50%' }}
                                alt="GraduatePro Logo"
                            />
                           
                            <p className="mt-2">
                                Graduatepro is an Early Career Accelerator bridging the gap between the demand for work-ready talent and the supply of decent, meaningful jobs.
                            </p>
                        </div>
                        <div className="col-md-4 col-12 mt-3 text-center footerLinks">
                            <div className="info fw-bold mb-3 " style={{ color: '#161B23', fontSize: '18px', fontFamily: 'Raleway', lineHeight: '27px' }}>Links</div>
                            <p style={{ fontSize: '21px' }}>
                                <a target="_blank" href="https://www.app.naomilucas.xyz">
                                    Shop
                                </a>
                            </p>
                            <p>
                                <a href="#">Privacy Policy</a>
                            </p>
                            <p>
                                <a href="#">Terms of Service</a>
                            </p>
                            <p>
                                <a target="_blank" href="https://naomilucas.xyz">
                                    Visit Naomi Lucas
                                </a>
                            </p>
                        </div>
                        <div className="col-md-4 col-12 text-center mt-3">
                            <div className="row justify-content-center">
                                <div className="fw-semibold mb-3 " style={{ color: '#161B23', fontSize: '18px', fontFamily: 'Raleway', lineHeight: '27px' }}>Social Share</div>

                                <div className="social-icons">
                                    <div className="icon-circle">
                                        <a
                                            href="https://www.facebook.com/NaomiLucasCo"
                                            target="_blank"
                                        >
                                            <i className="bx bxl-facebook"></i>
                                        </a>
                                    </div>
                                    <div className="icon-circle">
                                        <a
                                            href="https://www.instagram.com/naomilucasco"
                                            target="_blank"
                                        >
                                            <i className="bx bxl-instagram"></i>
                                        </a>
                                    </div>
                                    <div className="icon-circle">
                                        <a
                                            href="https://www.linkedin.com/company/naomi-lucas"
                                            target="_blank"
                                        >
                                            <i className="bx bxl-linkedin"></i>
                                        </a>
                                    </div>
                                </div>

                                <div className="col-md-12 col-lg-12 text-center mt-2 copyright-text">
                                    &copy; 2024 Naomi Lucas
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    );
}

export default Footer;
