import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import Swal from "sweetalert2";
import axios from "axios";
import { formdataAtom } from "../lib/atom";

export const usePaymentSelection = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);

  const submitPaymentSelection = async (selectedOption) => {
    const email = formdata.email;
    return Swal.fire({
      title: "Confirm Payment Option",
      text: `You selected: ${selectedOption.option}. Would you like to proceed?`,
      icon: "info",
      showCancelButton: true,
      allowEscapeKey: false,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, proceed",
      cancelButtonText: "Change selection",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const fd = {
          email: formdata.email,
          misc: {
            column_name: "Financial Info",
            payment_option: selectedOption.option,
          },
        };

        // const apiUrl = `https://credentials.naomilucas.xyz/api/bootcamp/update/${email}`;
        const apiUrl = process.env.REACT_APP_BASE_URL + "/application/misc";

        try {
          const res = await axios.post(apiUrl, fd);
          return res.data;
        } catch (error) {
          Swal.showValidationMessage(
            error?.response?.data?.message ||
              "Failed to process your selection. Please try again."
          );
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        // Show success message except for "Not Ready" option
        if (selectedOption.option !== "I'm Not Ready To Make Payment") {
          return Swal.fire({
            title: "Selection Recorded!",
            text: "Would you like to view the payment details?",
            icon: "success",
            confirmButtonText: "View Payment Details",
            allowEscapeKey: false,
            confirmButtonColor: "#ECA239",
            showCancelButton: true,
            cancelButtonText: "Later",
          });
        } else {
          return Swal.fire({
            title: "Preference Noted",
            html: `We completely understand if you're not ready to commit to this right now. A member of our admissions team will contact you shortly. In the meantime, join our global waitlist here 👉🏾 <a target="blank" href="https://chat.whatsapp.com/L9gBBfV6LHPAokFqfORUIF">https://chat.whatsapp.com/L9gBBfV6LHPAokFqfORUIF</a>`,
            icon: "success",
            confirmButtonText: "Okay",
            confirmButtonColor: "#ECA239",
            allowEscapeKey: false,
          });
        }
      }
      return { isConfirmed: false };
    });
  };

  return { submitPaymentSelection };
};
