import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';


const faqData = [
    {
        question: 'How do I know if I am eligible?',
        answer:
            `You are eligible if you:
             <div>
                 <p>- Graduated from any post-secondary institution </p>
                 <p>(Technical College, University, Mono/Polytechnic, Vocational School etc.) anywhere in the world</p>
                 <p>- Have between 0 and 8 years work experience (Internship experience also counts)</p>
                 <p>- Need employment in the next 12 weeks</p>
                 <p>- Own or have access to an internet enabled laptop/smart device to guarantee your uninterrupted participation in the bootcamp</p>
             </div>`
    },
    {
        question: 'How do I apply?',
        answer: 'Click on any of the buttons on this website.'
    },
    {
        question: 'When is the deadline?',
        answer:
            'We take applications on a rolling basis. You can always apply. If you miss a cohort, you are automatically waitlisted. We reach out to you once we commence admissions for the next cohort.'
    },
    {
        question: 'I\'m A Student. Can I apply??',
        answer:
            'The Employment Bootcamp was designed for those who have completed post-secondary education and have some work experience. If you are still in school or do not have any work experience, you are not eligible to apply at this time. You can always apply once you become eligible.'
    },
    {
        question: 'Do I have to pay to participate in The Employment Bootcamp?',
        answer: 'Yes. If you are selected, you will be required to pay a commitment fee. Please see the section on Key Information on this landing age.'
    },
    {
        question: 'How much does it cost to attend The Employment Bootcamp?',
        answer: 'Please see the section on Key Information on this landing age.'
    },
    {
        question: 'What if I can\'t afford to pay for The Employment Bootcamp?',
        answer:
            'We understand that this may be a significant investment for some people, so we have created a range of funding options. You can pay in 2 instalments. We also offer a limited number of scholarships per cohort.'
    },
    {
        question: 'Where will the bootcamp take place?',
        answer:
            'The Employment Bootcamp is 100% online. You will need an internet-enabled device, such as a smartphone, tablet, laptop, or desktop computer to access the learning platform and to participate in weekly live sessions and related activities.'
    },
    {
        question: 'Will I get a certificate after completing The Employment Bootcamp?',
        answer:
            'Even better! For every challenge you complete, you will get a shareable, verifiable digital badge. At the end of the bootcamp, you will get a certificate of completion as well. You will also get a private url where you can manage all the credentials you receive.'
    },
    {
        question: 'Will The Employment Bootcamp give me a job after the program?',
        answer:
            'We are working with recruiters across the globe to help our graduates secure decent jobs. As a participant of the program, you have direct access to these recruiters. From time to time, we also post Internships, Fellowships, Volunteer and Full/Part-time opportunities, so join our Slack community and  follow us on social media to be the first to know.'
    },
    {
        question: 'Can I apply for a job at Naomi Lucas after completing The Employment Bootcamp?',
        answer:
            'Upon completion of The Employment Bootcamp, there will be full-time, project-based and volunteer roles open to suitable participants of the program. You may apply for any that you feel qualified for.'
    },
    {
        question: 'I am an organisation and I want to partner with The Employment Bootcamp. How do I go about it?',
        answer:
            'Please send a mail to <a href="mailto:ask@naomilucas.xyz">ask@naomilucas.xyz</a> or <a href="mailto:learning@naomilucas.xyz">learning@naomilucas.xyz</a> with your request and contact details and you will receive a call.'
    },
    {
        question: 'I\'m having some issues with my application, who do I talk to?',
        answer:
            'Please send an email to <a href="mailto:ask@naomilucas.xyz">ask@naomilucas.xyz</a>, DM naomilucasco on Facebook, Instagram or LinkedIn. You may also Whatsapp <a href="tel:+2348186538971">0818 653 8971</a> to speak to a member of our admissions team.'
    },
];


function FAQ(props) {

   const [open, setOpen] = useState('');
  const toggle = (id) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };
    return (
        <section id="faq" className="faq faq-bg">
            <div className="container">
                <div className="justify-content-center align-items-center" style={{ paddingTop: '60px', paddingBottom: '72px' }}>
                    <div>
                        <div className="section-title">
                            <h2 className="text-center text-black font-family-Raleway">
                                <span>
                                    <svg style={{ marginTop: '-40px' }} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.0705 20.9499C9.0984 20.2356 6.23627 20.2823 3.22162 20.1967C2.63287 20.1801 2.1434 20.632 2.12567 21.2057C2.10794 21.7796 2.57256 22.2586 3.16131 22.2756C6.01991 22.3567 8.74017 22.2912 11.5633 22.9691C12.1343 23.1064 12.7124 22.765 12.8507 22.2077C12.9926 21.6507 12.6415 21.0868 12.0705 20.9499Z" fill="#161B23" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.8309 14.8815C13.2983 10.0355 7.46761 5.46945 1.58016 2.27801C1.0659 1.9997 0.416831 2.18098 0.133099 2.68285C-0.150634 3.18471 0.0338085 3.81828 0.548072 4.09659C6.24045 7.18266 11.8797 11.5986 16.2598 16.2846C16.657 16.7081 17.3309 16.7375 17.7636 16.3504C18.1963 15.9633 18.2282 15.3051 17.8309 14.8815Z" fill="#161B23" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M22.7834 13.6539C23.5318 9.22171 22.255 4.65326 20.4498 0.62307C20.2121 0.0972884 19.5844 -0.142562 19.0488 0.0872304C18.5097 0.317368 18.265 0.93119 18.4991 1.45698C20.1447 5.12741 21.3648 9.27855 20.6838 13.3153C20.5881 13.8817 20.9818 14.4172 21.5599 14.5104C22.138 14.604 22.6877 14.2199 22.7834 13.6539Z" fill="#161B23" />
                                    </svg>
                                </span>
                                STILL HAVE QUESTIONS?
                            </h2>
                        </div>

                       <Accordion flush open={open} toggle={toggle}>
        {faqData.map((faq, index) => (
          <AccordionItem key={index}>
            <AccordionHeader targetId={index + 1}>
              {faq.question}
            </AccordionHeader>
            <AccordionBody accordionId={index + 1}>
              <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FAQ;