import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import onboard from "../../assets/img/teb_cliparts/onboard.png";
import thrive from "../../assets/img/teb_cliparts/thrive.png";
import who_you from "../../assets/img/teb_cliparts/who_you_are.jpg";
import persona from "../../assets/img/teb_cliparts/persona.png";
import career from "../../assets/img/teb_cliparts/career.png";
import story from "../../assets/img/teb_cliparts/story.jpg";
import scout from "../../assets/img/teb_cliparts/scout.png";
import challenge from "../../assets/img/teb_cliparts/challenge.jpg";
import handshake from "../../assets/img/teb_cliparts/handshake.jpg";
import wrap_up from "../../assets/img/teb_cliparts/wrapup.jpg";
import victory from "../../assets/img/teb_cliparts/victory.png";
import CurvyLine from "./CurvyLine";

const Benefit = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      style={{ backgroundColor: "#f2f2f2", marginTop: "-20px" }}
      id="services"
      className="services "
    >
      <div className="section-title">
        <h2 className="text-center text-black font-family-Raleway">
          <span>
            <svg
              style={{ marginTop: "-40px" }}
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0705 20.9499C9.0984 20.2356 6.23627 20.2823 3.22162 20.1967C2.63287 20.1801 2.1434 20.632 2.12567 21.2057C2.10794 21.7796 2.57256 22.2586 3.16131 22.2756C6.01991 22.3567 8.74017 22.2912 11.5633 22.9691C12.1343 23.1064 12.7124 22.765 12.8507 22.2077C12.9926 21.6507 12.6415 21.0868 12.0705 20.9499Z"
                fill="#161B23"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8309 14.8815C13.2983 10.0355 7.46761 5.46945 1.58016 2.27801C1.0659 1.9997 0.416831 2.18098 0.133099 2.68285C-0.150634 3.18471 0.0338085 3.81828 0.548072 4.09659C6.24045 7.18266 11.8797 11.5986 16.2598 16.2846C16.657 16.7081 17.3309 16.7375 17.7636 16.3504C18.1963 15.9633 18.2282 15.3051 17.8309 14.8815Z"
                fill="#161B23"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.7834 13.6539C23.5318 9.22171 22.255 4.65326 20.4498 0.62307C20.2121 0.0972884 19.5844 -0.142562 19.0488 0.0872304C18.5097 0.317368 18.265 0.93119 18.4991 1.45698C20.1447 5.12741 21.3648 9.27855 20.6838 13.3153C20.5881 13.8817 20.9818 14.4172 21.5599 14.5104C22.138 14.604 22.6877 14.2199 22.7834 13.6539Z"
                fill="#161B23"
              />
            </svg>
          </span>
          HERE’S WHAT AWAITS YOU
        </h2>
      </div>

      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-auto">
            <div className="beneicon-container rounded-4">
              <span className="icon">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 40 40"
                >
                  <path
                    fill="#000"
                    d="M26.667 10a6.667 6.667 0 11-13.335 0 6.667 6.667 0 0113.335 0zm-.537 12.505c-.788.008-1.523.038-2.163.123-1.072.145-2.245.489-3.214 1.459-.97.968-1.313 2.141-1.456 3.213-.13.963-.13 2.14-.13 3.39v.287c0 1.25 0 2.426.13 3.39.1.751.3 1.553.745 2.3H20c-13.333 0-13.333-3.359-13.333-7.5 0-4.142 5.97-7.5 13.333-7.5 2.21 0 4.295.301 6.13.838z"
                  ></path>
                  <path
                    fill="#000"
                    fillRule="evenodd"
                    d="M27.5 36.667c-2.75 0-4.125 0-4.978-.855-.855-.854-.855-2.229-.855-4.979s0-4.125.855-4.978C23.375 25 24.75 25 27.5 25c2.75 0 4.125 0 4.978.855.855.853.855 2.228.855 4.978 0 2.75 0 4.125-.855 4.979-.853.855-2.228.855-4.978.855zm3.28-7.09a.973.973 0 00-1.375-1.375l-3.2 3.2-.61-.609a.97.97 0 00-1.66.688.972.972 0 00.285.687l1.297 1.297a.971.971 0 001.375 0l3.888-3.888z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </div>
            <span className="text-black">
              1 Week <br />
              Onboarding
            </span>
          </div>
          <div className="col-auto">
            <div className="beneicon-container rounded-4">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 40 40"
                >
                  <path
                    fill="#000"
                    stroke="#000"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M10 3.333H3.333V10H10V3.333zM36.667 30H30v6.667h6.667V30zM10 30H3.333v6.667H10V30zM36.667 3.333H30V10h6.667V3.333z"
                  ></path>
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M6.667 30V10m26.666 20V10M10 6.667h20M10 33.333h20"
                  ></path>
                  <path
                    fill="#000"
                    fillRule="evenodd"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4"
                    d="M13.333 13.333h8v5.334h5.334v8h-8v-5.334h-5.334v-8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            </div>
            <span className="text-black">
              Start <br /> Bootcamp
            </span>
          </div>
          <div className="col-auto">
            <div className="beneicon-container rounded-4">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 40 40"
                >
                  <path
                    fill="#000"
                    d="M18.333 13.333h3.334V15h-3.334v-1.667zm0-6.666h3.334v1.666h-3.334V6.667zm0 10h3.334v1.666h-3.334v-1.666z"
                  ></path>
                  <path
                    fill="#000"
                    d="M35 20v-5a21.873 21.873 0 00-13.923 5h-2.154A21.874 21.874 0 005 15v5a1.667 1.667 0 00-1.667 1.667V25A1.667 1.667 0 005 26.667v6.666a21.922 21.922 0 0115 5.917 22 22 0 0115-5.917v-6.666A1.666 1.666 0 0036.667 25v-3.333A1.666 1.666 0 0035 20zM15 8.333A1.667 1.667 0 1015 5a1.667 1.667 0 000 3.333zM25 8.333A1.667 1.667 0 1025 5a1.667 1.667 0 000 3.333z"
                  ></path>
                  <path
                    fill="#000"
                    d="M26.667 13.333H13.333a5.005 5.005 0 01-5-5V5a5.005 5.005 0 015-5h13.334a5.005 5.005 0 015 5v3.333a5.005 5.005 0 01-5 5zm-13.334-10A1.669 1.669 0 0011.667 5v3.333A1.668 1.668 0 0013.333 10h13.334a1.668 1.668 0 001.666-1.667V5a1.668 1.668 0 00-1.666-1.667H13.333z"
                  ></path>
                </svg>
              </span>
            </div>
            <span className="text-black">
              8 Weeks <br /> Complete <br /> Learning Game
            </span>
          </div>
          <div className="col-auto">
            <div className="beneicon-container rounded-4">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 40 40"
                >
                  <path
                    fill="#000"
                    d="M19.258 6.02l.13.008h4.592l.106-.006a7.4 7.4 0 011.712.012 5 5 0 012.172.616 7.384 7.384 0 013.144 2.7 7.32 7.32 0 01.292 7.508l-6.43-6.512a1 1 0 00-.712-.296h-2.662a1 1 0 00-.604.202l-4.296 3.254a2.232 2.232 0 01-3.35-.8A2.192 2.192 0 0114.004 10l5.254-3.98zm-7.994 17.71l-.028.026-1.526 1.52-.026.024a1.2 1.2 0 01-2.02-.867 1.18 1.18 0 01.352-.841l1.528-1.518a1.2 1.2 0 011.694 0c.46.456.468 1.19.026 1.656zm-.162 2.98c-.44.466-.432 1.2.026 1.656a1.2 1.2 0 001.694 0l1.528-1.518a1.182 1.182 0 00-.186-1.832 1.2 1.2 0 00-1.48.124l-.028.026-1.526 1.518-.028.026zm-3.604-7.104a1.18 1.18 0 010 1.682L5.97 22.806a1.2 1.2 0 01-2.046-.841 1.18 1.18 0 01.352-.841l1.528-1.52a1.2 1.2 0 011.694 0m9.964 8.652a1.18 1.18 0 010 1.682l-1.528 1.52a1.2 1.2 0 01-2.038-.702 1.18 1.18 0 01.17-.762l.006-.008c.048-.077.104-.148.168-.212l1.528-1.518a1.2 1.2 0 011.694 0zM7.062 8.8a9.52 9.52 0 018.608-2.574l-2.874 2.18A4.194 4.194 0 0012 14.3a4.232 4.232 0 005.912.8l4.028-3.05h1.818l3.876 3.85.008-.01 3.216 3.258.048.046 1.73 1.73a2.04 2.04 0 01-2.734 3.02l-.146-.144-.108-.094-1.7-1.7a1 1 0 10-1.416 1.414l1.804 1.804c.07.067.14.131.21.194l.06.06a1.382 1.382 0 11-1.954 1.956l-.266-.264a1 1 0 00-1.706.72 1.002 1.002 0 00.292.726l.35.35a1.264 1.264 0 01-1.786 1.786l-.178-.18a3.98 3.98 0 00-.19-.206l-.326-.326a1 1 0 00-1.414 1.414l.244.244c.03.04.064.08.1.118l.098.096a1.296 1.296 0 01-1.922 1.728l-1.73-1.63.664-.66a3.167 3.167 0 000-4.5 3.2 3.2 0 00-2.176-.932 3.16 3.16 0 00-.936-2.16 3.2 3.2 0 00-2.174-.932 3.16 3.16 0 00-.938-2.16 3.2 3.2 0 00-2.87-.878 3.2 3.2 0 00-2.975-2.52 3.22 3.22 0 00-2.187.714 9.38 9.38 0 012.44-9.18"
                  ></path>
                </svg>
              </span>
            </div>
            <span className="text-black">
              1 Week <br /> Handshake
            </span>
          </div>
          <div className="col-auto">
            <div className="beneicon-container rounded-4">
              <span className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="none"
                  viewBox="0 0 40 40"
                >
                  <path
                    fill="#000"
                    d="M20 7L1.757 13.843 20 22.964l8.69-4.345-8.152-2.492A1.25 1.25 0 1120 13.75l-.207.672 1.449.445v.005l2.137.653 12.168 3.744v.949a1.25 1.25 0 00-.395 1.628c.1.183.243.338.418.451-.57 2.15-.57 7.03-.57 8.953 1.25.812 1.25.842 2.5 0 0-1.923 0-6.801-.57-8.952a1.25 1.25 0 00.023-2.081V18.23l-4.634-1.426 5.924-2.963L20 7.001zM9.346 19.454L8.32 25.605c2.045.267 4.492 1.456 6.74 2.861 1.28.8 2.477 1.677 3.45 2.528.595.52 1.092 1.02 1.49 1.51.398-.49.896-.99 1.49-1.51.973-.851 2.171-1.729 3.45-2.528 2.249-1.405 4.695-2.594 6.74-2.861l-1.026-6.152h-.488L20 24.536 9.834 19.453h-.488z"
                  ></path>
                </svg>
              </span>
            </div>
            <span className="text-black">Graduate</span>
          </div>
        </div>
      </div>
      <div className="background-svg mt-5 container">
        <div className="row justify-content-center cardCon">
          <CurvyLine screenWidth={screenWidth} />
          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card1">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={onboard}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Onboarding And Immersion
                </h3>
                <p className="card-text text-center">
                  Get all the support and resources you need to go through the
                  program seamlessly.
                </p>
              </div>
            </div>
            <div className="dot1"></div>
          </div>
          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card2">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={who_you}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Level 1: Understand Who You Are
                </h3>
                <p className="card-text text-center">
                  Learn more about the concepts of identity, values, purpose and
                  personality. Mapping your strengths and weaknesses and
                  creating SMART goals for your career
                </p>
              </div>
            </div>
            <div className="dot2"></div>
            <div className="dot2a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card3">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={persona}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Level 2: Build Your Persona
                </h3>
                <p className="card-text text-center">
                  Understand the fundamentals of perception management, personal
                  brand building, online visibility and business communication
                </p>
              </div>
            </div>
            <div className="dot3"></div>
            <div className="dot3a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card4">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={career}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Level 3: Design Your Career
                </h3>
                <p className="card-text text-center">
                  Learn how the labour market works, conduct a skills audit,
                  conduct career research and create your career map
                </p>
              </div>
            </div>
            <div className="dot4"></div>
            <div className="dot4a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card5">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={story}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Level 4: Craft Your Story
                </h3>
                <p className="card-text text-center">
                  A hand-on project to help you understand The fundamentals of
                  CV building, cover letter and portfolio development.{" "}
                </p>
              </div>
            </div>
            <div className="dot5"></div>
            <div className="dot5a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card6">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={scout}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Level 5: Scout The Terrain
                </h3>
                <p className="card-text text-center">
                  Learn how to create your own opportunities, get a guide for
                  effective remote work and managing a job application process.
                </p>
              </div>
            </div>
            <div className="dot6"></div>
            <div className="dot6a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card7">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={challenge}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Level 6: Rise To The Challenge
                </h3>
                <p className="card-text text-center">
                  Learn mental preparation for job search success, how to stay
                  safe while job hunting, interview/pitch prep.
                </p>
              </div>
            </div>
            <div className="dot7"></div>
            <div className="dot7a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card8">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={victory}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Level 7: Claim Your Victory
                </h3>
                <p className="card-text text-center">
                  Learn how to evaluate job offers, negotiate fair compensation
                  and close a job application process.
                </p>
              </div>
            </div>
            <div className="dot8"></div>
            <div className="dot8a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card9">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={thrive}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Level 8: Thrive
                </h3>
                <p className="card-text text-center">
                  Explore lessons on meaningful work, diversity, equity and
                  inclusion, office politics and workplace discrimination. Learn
                  how to succeed at entrepreneurship, deal with societal
                  pressure and make the most of your career.
                </p>
              </div>
            </div>
            <div className="dot9"></div>
            <div className="dot9a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card10">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={handshake}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Handshake
                  <br /> (A week-long marathon of panel sessions)
                </h3>
                <p className="card-text text-center">
                  Join eye-opening panels on Creating Opportunities, Thriving
                  Abroad, Switching Careers and Remote Work. Hear first-hand
                  from Startup Founders and business leaders.{" "}
                </p>
              </div>
            </div>
            <div className="dot10"></div>
            <div className="dot10a"></div>
          </div>

          <div className="col-xl-5 col-sm-10 mb-3">
            <div className="card p-4 border-anibe rounded-4 card11">
              <div className="d-flex justify-content-center align-items-center">
                <div className="icon-container">
                  <img
                    className="img-fluid"
                    src={wrap_up}
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
              </div>
              <div className="card-body">
                <h3
                  style={{ fontSize: "16px" }}
                  className="card-title fw-semibold text-center"
                >
                  Bootcamp Wrap Up
                </h3>
                <p className="card-text text-center">
                  Attend wrap up briefing with Program Director, commencement
                  event and Join the Graduatepro Career Network
                </p>
              </div>
            </div>
            <div className="dot11"></div>
          </div>

          <div className="text-center">
            <a href="/apply" target="blank" className="btn-about">
              Apply Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefit;
