import { useAtom } from "jotai";
import { formdataAtom } from "../lib/atom";

export const useFormValidation = (component) => {
  const [formdata] = useAtom(formdataAtom);

  const getFields = (component) => {
    const componentTypes = {
      biodata: ["firstname", "lastname", "gender", "country", "city"],
      communications: [
        "email",
        "phone",
        "whatsapp_phone",
        "referral_source",
        "referral_detail",
      ],
      login: ["email"],
      eligibility: [
        "openness_to_tools",
        "work_disruption_feelings",
        "feedback_handling",
        "approach_to_new_tasks",
        "education_focus",
        "highest_qualification",
      ],
      employment: [
        "employment_status",
        "unemployment_duration",
        "work_experience_years",
        "exit_timeline",
        "reason_for_leaving",
        "service_completion_date",
      ],
      others: [
        "key_challenge",
        "leadership_experience",
        "distractions",
        "bootcamp_expectations",
      ],
      financialAid: [
        "typeOfPhone",
        "haveLaptop",
        "parentAlive",
        "employed",
        "affordableFee",
        "whyBootcamp",
        "anythingElse",
      ],
      default: [],
    };
    return componentTypes[component] || componentTypes["default"];
  };

  const getMessage = (field) => {
    const messageTypes = {
      firstname: "Please tell us your first name",
      lastname: "Please tell us your last name",
      gender: "Choose a gender",
      country: "What country are you from?",
      city: "Country and city of residence is invalid",
      email: "Please provide a valid email address",
      phone: "Phone number is required",
      phoneFormat:
        "Phone number must be in the format: +XXXXXXXXXXXX, where the first +xx or +xxx are country codes", // Updated message
      referral_source: "Tell us how you heard about us",
      referral_detail: "Tell us the person or organisation",
      openness_to_tools: "Please tell us how adaptable you are",
      approach_to_new_tasks: "Please let us know about your readiness",
      education_focus:
        "Please let us know what best describes your educational focus",
      highest_qualification: "Let us know your highest level of education",
      work_disruption_feelings:
        "Let us know how you go about performing a task you have never done before",
      feedback_handling: "Please let us know how you deal with feedback",
      employment_status: "Please choose your employment status",
      unemployment_duration: "Let us know how long you have been out of a job",
      reason_for_leaving: "Let us know why you want to leave your present job",
      exit_timeline: "Let us know when you are looking to leave",
      service_completion_date: "Let us know when you will be done with service",
      work_experience_years: "Let us know how long you have worked",
      jobRange: "Let us know how badly you need a job",
      hasUploadedCV: "Please attach your CV as a PDF or Word file.",
      participationReason:
        "Kindly tell us why you are filling this application form and what you wish to make out of it",
      key_challenge:
        "Please tell us about the most challenging thing you've ever done.",
      leadership_experience:
        "Please provide detailed examples of your leadership experience, including specific responsibilities and achievements.",
      distractions:
        "Please explain your strategies for managing distractions. Provide specific examples of techniques you use to stay focused.",
      bootcamp_expectations:
        "What difference are you hoping The Employment Bootcamp will make in your life?",
      agreement: "Please accept the agreement to continue",
      agreement1: "Please accept the second agreement to continue",
      agreement2: "Please accept the payment agreement to continue",
      typeOfPhone: "Please state the type of phone you use",
      haveLaptop: "Please state if you have a laptop with either Yes or No",
      parentAlive:
        "Please state if your parents are aliv and state what they do",
      employed: "Please state your employment status",
      affordableFee: "Please the amount you can afford to pay",
      whyBootcamp: "Please your reason for this bootcamp",
      default: "",
    };

    return messageTypes[field] || messageTypes["default"];
  };

  const validate = async () => {
    const fields = getFields(component);
    const errors = [];

    for (const field of fields) {
      const fieldValue = formdata[field] || "";

      if (field === "referral_detail" && !validateReferralPerson()) continue;
      if (field === "unemployment_duration" && !validateUnemploymentDuration())
        continue;
      if (
        field === "reason_for_leaving" &&
        !validateWhyAndWhenLeaveCurrentPosition()
      )
        continue;
      if (
        field === "exit_timeline" &&
        !validateWhyAndWhenLeaveCurrentPosition()
      )
        continue;
      if (
        field === "service_completion_date" &&
        !validateServiceCompletionDate()
      )
        continue;

      if (field === "whatsapp_phone") continue;

      if (!fieldValue.trim()) {
        const err = getMessage(field);
        errors.push(err);
      }
    }

    // Additional validation for email and phone matching
    if (component === "login") {
      if (formdata.email && !validateEmail(formdata.email)) {
        errors.push(getMessage("email"));
      }
    }

    if (component === "communications") {
      if (formdata.email && !validateEmail(formdata.email)) {
        errors.push(getMessage("email"));
      }
      if (formdata.phone && !validatePhone(formdata.phone)) {
        errors.push(getMessage("phoneFormat"));
      }
    }

    // Agreement validation for the "others" component
    if (component === "others") {
      if (!formdata.agreement) {
        errors.push(getMessage("agreement"));
      }
      if (!formdata.agreement1) {
        errors.push(getMessage("agreement1"));
      }
      if (!formdata.agreement2) {
        errors.push(getMessage("agreement2"));
      }
    }

    // financial aid validation
    if (component === "financialAid") {
      if (!formdata.typeOfPhone) {
        errors.push(getMessage("typeOfPhone"));
      }
    }

    return errors; // Ensure errors array is always returned
  };

  const validateReferralPerson = () => {
    return (
      formdata.referral_source ===
        "It was recommended by a friend or a family member" ||
      formdata.referral_source === "Through an organisation"
    );
  };

  const validateUnemploymentDuration = () => {
    return (
      formdata.employment_status ===
      "I am unemployed and actively looking for a job"
    );
  };

  const validateWhyAndWhenLeaveCurrentPosition = () => {
    return (
      formdata.employment_status === "I am employed but looking for another job"
    );
  };

  const validateServiceCompletionDate = () => {
    return formdata.employment_status === "I am currently serving (NYSC)";
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^\+?[0-9]{10,15}$/;
    return regex.test(phone);
  };

  return { validate, validateEmail, validatePhone };
};