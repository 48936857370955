import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ isAllowed, element }) => {
  if (!isAllowed) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/apply" replace />;
  }

  // Render the desired component if the user is authenticated
  return element;
};

export default ProtectedRoute;