import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CheckBox from "../../Form/CheckBox";
import { useAtom } from "jotai";
import { formdataAtom, progressAtom } from "../../../lib/atom";
import { useFormValidation } from "../../../hooks/useFormValidation";
import SaveAndContinueWithBackButton from "../../Utility/SaveAndContinueWithBackButton";
import { useUpdateOther } from "../../../hooks/useUpdateOther";

const ModalComponent = ({ showModal, setShowModal }) => {
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { validate } = useFormValidation("others");
  const { updateOther } = useUpdateOther();
  const [, setProgress] = useAtom(progressAtom);

  useEffect(() => {
    setProgress(6);
  }, []);

  const handleCheckboxChange = (field) => {
    setFormdata((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleModalSubmit = async () => {
    setIsLoading(true);

    const validationErrors = await validate();
    if (validationErrors.length) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    try {
      await updateOther();
      setShowModal(false);
    } catch (error) {
      setErrors(["An error occurred while submitting your application."]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4
              className="text-start text-uppercase fw-bold modal-title"
              style={{ color: "black" }}
            >
              Key Information:
            </h4>
          </div>
          <div className="modal-body">
            <p className="fw-bold" style={{ color: "black" }}>
              By submitting your application, you confirm that you understand
              and agree with the following:
            </p>
            <ul style={{ color: "black" }}>
              <li>
                <CheckBox
                  label="You are available and ready to join the next cohort of The Employment Bootcamp."
                  name="agreement"
                  value={formdata.agreement}
                  // onChange={() => handleCheckboxChange("agreement")}
                />
              </li>
              <li>
                <CheckBox
                  label="You are open-minded and ready to do what it takes to see the results you require."
                  name="agreement1"
                  value={formdata.agreement1}
                  // onChange={() => handleCheckboxChange("agreement1")}
                />
              </li>
              <li>
                <CheckBox
                  label="Payment of commitment fee:"
                  name="agreement2"
                  value={formdata.agreement2}
                  // onChange={() => handleCheckboxChange("agreement2")}
                />
                <ul>
                  <li>
                    <b>Nigerian Applicants:</b> NGN60,000
                  </li>
                  <li>
                    <b>International Applicants:</b> 50 US dollars
                  </li>
                  <li>
                    <b>Applicants from Partners:</b> Discounts are as agreed
                    with our partners.
                  </li>
                  <li>
                    <em>
                      Instalmental payment plans and financial aid available.
                    </em>
                  </li>
                </ul>
              </li>
            </ul>
            {errors.map((error, index) => (
              <div key={index} className="alert alert-danger text-start">
                {error}
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <SaveAndContinueWithBackButton
              action={"Submit Application"}
              handleComms={handleModalSubmit}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;
