import { useAtom } from "jotai";
import React from "react";
import { formdataAtom } from "../../lib/atom";

const Radio = ({ name, items, value, flex = true, onChangeFunc }) => {
  const [formdata, setFormdata] = useAtom(formdataAtom);
  return (
    <div className={flex ? "d-flex" : ""}>
      {items.map((item, index) => (
        <div key={index} className="form-check me-3">
          <input
            className="form-check-input"
            type="radio"
            name={name}
            id={name + item.value}
            value={item.value}
            checked={item.value === value}
            onChange={(e) =>
              setFormdata({ ...formdata, [name]: e.target.value })
            }
            style={{ border: "1px solid black" }}
          />
          <label style={{ fontWeight: "normal" }} htmlFor={name + item.value}>
            {item.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default Radio;
