export const referralItems = [
  {
    value: "It was recommended by a friend or a family member",
    label: "It was recommended by a friend or a family member",
  },
  {
    value: "Social media",
    label:
      "On social media (Facebook, Instagram, YouTube, Twitter, TikTok, etc.)",
  },
  { value: "Through email marketing", label: "Through email marketing" },
  {
    value:
      "At a Naomi Lucas event or from a member of The Employment Bootcamp team",
    label:
      "At a Naomi Lucas event or from a member of The Employment Bootcamp team",
  },
  {
    value: "Through an organisation",
    label:
      "Through an organisation (E.g. An Employment Bootcamp partner, Alumni Associations etc.)",
  },
];