import React, { useRef, useState } from "react";
import Slider from "react-slick";

import Naomi from "../../assets/img/naomi.png";

const facilitators = [
  {
    name: "J’odie",
    image: "./assets/facultyBack/jodie1.jpg",
    url: "https://www.linkedin.com/in/tochukwu-macfoy-05947b103/",
    title: "Musician, Coordinator, Chinua Children Care Foundation",
  },
  {
    name: "Matthew Jackman",
    image: "./assets/facultyBack/Mathew.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Mental Health Activist",
  },
  {
    name: "Daudi Mkoma",
    image: "./assets/facultyBack/Daudi.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Human Resources Professional",
  },
  {
    name: "Kathleen Ndongmo",
    image: "./assets/facultyBack/kathleen1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Digital Content Manager, Viamo",
  },

  {
    name: "Toluwase Olaniyan",
    image: "./assets/facultyBack/toluwase1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "CEO, Worden",
  },

  {
    name: "Ese Oraka",
    image: "./assets/facultyBack/ese1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Founding Partner, Adelphi Innovation Consulting",
  },

  {
    name: "Tochukwu McFoy",
    image: "./assets/facultyBack/mcfoy1.jpg",
    url: "https://www.linkedin.com/in/tochukwu-macfoy-05947b103/",
    title: "CEO, Energize Music",
  },
  {
    name: "Mayowa Adegoke",
    image: "./assets/facultyBack/mayowa1.jpg",
    url: "https://www.linkedin.com/in/mayowaadegoke/",
    title: "Middle East Correspondent, Channels TV",
  },

  {
    name: "Alex Ohai",
    image: "./assets/facultyBack/alex1.jpg",
    url: "https://www.linkedin.com/in/alexnnekaohai/",
    title: "Head, HR, 9 Payment Service Bank",
  },
  {
    name: "Olusola John",
    image: "./assets/facultyBack/olusola1.jpg",
    url: "https://www.linkedin.com/in/johnolusola/",
    title: "Founder, Careerwise",
  },
  {
    name: "Maryam Aliko Mohammed",
    image: "./assets/facultyBack/maryam1.jpg",
    url: "https://www.linkedin.com/in/maryamalikomohammed/",
    title: "CEO, Modalali",
  },
  {
    name: "Ibukun Amosu",
    image: "./assets/facultyBack/ibukun1.jpg",
    url: "https://www.linkedin.com/in/ibukunamosu/",
    title: "Founder, The Intern Place",
  },
  {
    name: "Gloria Alado",
    image: "./assets/facilitators/Gloria_Alado.png",
    url: "https://www.linkedin.com/in/gloriangalado/",
    title: "Talent Sourcing Specialist, Pariti",
  },
  {
    name: "Dr. Jibril AbdulMalik",
    image: "./assets/facultyBack/jibril1.jpg",
    url: "https://www.linkedin.com/in/jabdulmalik/",
    title: "Founder/CEO, Asido Foundation",
  },
  {
    name: "Segun Lawal",
    image: "./assets/facultyBack/segun1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Founder, Spirit of David",
  },
  {
    name: "Folakemi Onamade",
    image: "./assets/facultyBack/folakemi1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Team Lead, The Employment Bootcamp",
  },
  {
    name: "Toyyib Adelodun",
    image: "./assets/facultyBack/toyyib1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Chief Service Officer, Toyibb Adelodun International",
  },
  // other facilitators data...
];

const assessors = [
  {
    name: "Henrietta Ogu",
    image: "./assets/facultyBack/gloria1.jpg",
    url: "https://www.linkedin.com/in/h",
    title: "Project Manager, Volition Capital",
  },
  {
    name: "Raquel Kasham Daniel",
    image: "./assets/facultyBack/raquel1.jpg",
    url: "https://www.linkedin.com/in/raqueldaniel",
    title: "Co-founder, Bambini Africa",
  },
  {
    name: "Akan Ebong",
    image: "./assets/facultyBack/akan1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "President/CEO, Amazing Ibom Mushroom Growers",
  },

  {
    name: "Ijeoma Igbaji",
    image: "./assets/facultyBack/ijeoma1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "CEO, White Rabbit NG",
  },
  {
    name: "Dare Joseph Akinfosile",
    image: "./assets/facultyBack/dare1.jpg",
    url: "https://www.linkedin.com/in/",
    title:
      "Health, Safety & Environment (HSE) Manager",
  },
  {
    name: "Olaronke Aribiyi",
    image: "./assets/facultyBack/olaronke1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Business Development Manager, Sparkle",
  },

  {
    name: "Glorie Okorie",
    image: "./assets/facultyBack/gift1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Innovation Lead, Impact Hub",
  },

  {
    name: "Fortune Onyemuwa",
    image: "./assets/facultyBack/fortune1.jpg",
    url: "https://www.linkedin.com/in/",
    title: "Lead User Interface and Experience Designer, Kominiti",
  },

  {
    name: "Temitope Akinkunmi",
    image: "./assets/facultyBack/temitope1.jpg",
    url: "https://www.linkedin.com/in/temitope-akinkunmi-41188384",
    title: "CEO, Fisibul Networks Int’l",
  },
  {
    name: "Christiana Lamba",
    image: "./assets/facultyBack/christiana1.jpg",
    url: "https://www.linkedin.com/in/christiana-lamba-911150b3",
    title: "Team Lead, LeMakarios Hub",
  },

  {
    name: "Temidayo Salako",
    image: "./assets/facultyBack/temidayo1.jpg",
    url: "https://www.linkedin.com/in/temidayosalako/",
    title: "Head, Marketing, Tunga",
  },
  {
    name: "Olumide Campbell",
    image: "./assets/facultyBack/olumide1.jpg",
    url: "https://www.linkedin.com/in/olumide-campbell-5ba50310?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BvfJXSYkOTRuylnt%2B9bx4NA%3D%3D",
    title: "Executive Director, Etu Odi Ltd.",
  },

  {
    name: "Ayodeji Jeremiah",
    image: "./assets/facultyBack/ayomide1.jpg",
    url: "https://www.linkedin.com/in/ayodejijeremiah1973",
    title: "Senior Management Consultant, SOGA",
  },
  {
    name: "Osho Ademola Joel",
    image: "./assets/facultyBack/osho1.jpg",
    url: "https://www.linkedin.com/in/oshoademolajoel",
    title: "CEO, Supretec.com",
  },
  {
    name: "Obinna Igwebuike",
    image: "./assets/facultyBack/obinna1.jpg",
    url: "https://www.linkedin.com/in/obinnaigwebuike/",
    title: "Co-Founder, Commercial Development Lead, kliqr",
  },
  {
    name: "Onuoha Anayor Daniel",
    image: "./assets/facultyBack/daniel1.jpg",
    url: "https://www.linkedin.com/in/anayor-daniel/",
    title: "Technical Recruiter, Rova",
  },
  {
    name: "Olumide Gbadebo",
    image: "./assets/facultyBack/gbadebo1.jpg",
    url: "http://linkedin.com/in/olumide-gbadebo-7292a098/",
    title: "CEO, Adunni Organics",
  },
];

const Faculty = () => {
  const facilitatorSliderRef = useRef(null);
  const assessorSliderRef = useRef(null);

  const nextFacilitatorSlide = () => {
    facilitatorSliderRef.current.slickNext();
  };

  const prevFacilitatorSlide = () => {
    facilitatorSliderRef.current.slickPrev();
  };

  const nextAssessorSlide = () => {
    assessorSliderRef.current.slickNext();
  };

  const prevAssessorSlide = () => {
    assessorSliderRef.current.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    autoplay: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="faculty" className="about">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center CTA" style={{ minWidth: "50%" }}>
            <div className="section-title">
              <h2 className="text-center text-black font-family-Raleway">
                <span>
                  <svg
                    style={{ marginTop: "-40px" }}
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.0705 20.9499C9.0984 20.2356 6.23627 20.2823 3.22162 20.1967C2.63287 20.1801 2.1434 20.632 2.12567 21.2057C2.10794 21.7796 2.57256 22.2586 3.16131 22.2756C6.01991 22.3567 8.74017 22.2912 11.5633 22.9691C12.1343 23.1064 12.7124 22.765 12.8507 22.2077C12.9926 21.6507 12.6415 21.0868 12.0705 20.9499Z"
                      fill="#161B23"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.8309 14.8815C13.2983 10.0355 7.46761 5.46945 1.58016 2.27801C1.0659 1.9997 0.416831 2.18098 0.133099 2.68285C-0.150634 3.18471 0.0338085 3.81828 0.548072 4.09659C6.24045 7.18266 11.8797 11.5986 16.2598 16.2846C16.657 16.7081 17.3309 16.7375 17.7636 16.3504C18.1963 15.9633 18.2282 15.3051 17.8309 14.8815Z"
                      fill="#161B23"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.7834 13.6539C23.5318 9.22171 22.255 4.65326 20.4498 0.62307C20.2121 0.0972884 19.5844 -0.142562 19.0488 0.0872304C18.5097 0.317368 18.265 0.93119 18.4991 1.45698C20.1447 5.12741 21.3648 9.27855 20.6838 13.3153C20.5881 13.8817 20.9818 14.4172 21.5599 14.5104C22.138 14.604 22.6877 14.2199 22.7834 13.6539Z"
                      fill="#161B23"
                    />
                  </svg>
                </span>
                AND ROOTING FOR YOU ARE...
              </h2>
            </div>

            <div className="row">
              <div className="col-lg-5 d-flex flex-column flex-lg-row align-items-stretch justify-content-center justify-content-lg-start">
                <div className="col-lg-7 d-flex flex-column align-items-center align-items-lg-stretch">
                  <img
                    src={Naomi}
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      marginBottom: "10px",
                    }}
                    alt=""
                    className="img-fluid rounded-4"
                  />
                  <div className="d-flex flex-column align-items-center">
                    <b>Naomi Lucas</b>
                    <p>Program Director</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 ps-0">
                <div className="container d-flex flex-column">
                  <p
                    className="text-start text-lg-left text-center-md"
                    style={{ marginTop: "20px" }}
                  >
                    Naomi is a dynamic leader in Media, Youth, and Technology,
                    renowned for her impactful initiatives worldwide. As the
                    founder of Graduatepro Early Careers Accelerator and
                    International Youth Development Centre, she's committed to
                    enhancing economic opportunities for youth. With accolades
                    from esteemed institutions like the University of Cambridge
                    and the University of Oxford, Naomi leverages her expertise
                    to bridge the gap between talent demand and job
                    availability, driven by her strategic mindset and extensive
                    network.
                  </p>
                  <div
                    className="row justify-content-center justify-content-lg-start"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                  >
                    <div
                      className="col-6 col-md-3 col-lg-2 d-flex justify-content-around align-items-center"
                      style={{ paddingLeft: "5px", paddingRight: "5px" }}
                    >
                      <a
                        href="https://www.facebook.com/Nayomie"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="rounded-circle bg-black d-flex justify-content-center align-items-center"
                          style={{ width: "50px", height: "50px" }}
                        >
                          <i className="bg-light rounded-5 bx bxl-facebook text-black"></i>
                        </div>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/naomilucas/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="ms-2 rounded-circle bg-black d-flex justify-content-center align-items-center"
                          style={{ width: "50px", height: "50px" }}
                        >
                          <i className=" bx bxl-linkedin text-white"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {/* Your carousel for facilitators */}
              <p
                style={{
                  fontSize: "26px",
                  marginTop: "60px",
                  marginBottom: "40px",
                }}
                className="fw-bold"
              >
                Facilitators
              </p>

              <div className="sliderCon">
                <div className="sliderArrow">
                  <button className="arrow1" onClick={prevFacilitatorSlide}>
                    <i class='bx bx-left-arrow-alt' ></i>
                  </button>
                  <button className="arrow2" onClick={nextFacilitatorSlide}>
                    <i class='bx bx-right-arrow-alt'></i>
                  </button>
                </div>
                <Slider {...settings} ref={facilitatorSliderRef}>
                  {facilitators.map((facilitator, index) => (
                    <div
                      key={index}
                      className="text-center d-flex flex-wrap justify-content-center"
                    >
                      <img
                        style={{ height: "281px" }}
                        className="img-fluid me-3 me-sm-0 rounded-4"
                        src={facilitator.image}
                        alt={`${facilitator.name} avatar`}
                      />
                      <p className="mt-3">
                        <b className="card-text text-black">{facilitator.name}</b>{" "}
                        <br />
                        <span className="card-text text-black">
                          {facilitator.title}
                        </span>
                      </p>
                    </div>
                  ))}
                </Slider>
              </div>

              {/* Your carousel for assessors */}
              <p
                style={{
                  fontSize: "26px",
                  marginTop: "40px",
                  marginBottom: "40px",
                }}
                className="fw-bold"
              >
                Assessors
              </p>

              <div className="sliderCon">
                <div className="sliderArrow">
                  <button className="arrow1" onClick={prevAssessorSlide}>
                    <i class='bx bx-left-arrow-alt' ></i>
                  </button>
                  <button className="arrow2" onClick={nextAssessorSlide}>
                    <i class='bx bx-right-arrow-alt'></i>
                  </button>
                </div>

                <Slider {...settings} ref={assessorSliderRef}>
                  {assessors.map((assessor, index) => (
                    <div
                      key={index}
                      className="text-center d-flex flex-wrap justify-content-center"
                    >
                      <img
                        style={{ height: "281px" }}
                        className="img-fluid me-3 me-sm-0 rounded-4"
                        src={assessor.image}
                        alt={`${assessor.name} avatar`}
                      />
                      <p className="mt-3">
                        <b className="card-text text-black">{assessor.name}</b>
                        <br />
                        <span className="card-text text-black">
                          {assessor.title}
                        </span>
                      </p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div style={{ marginTop: "20px" }} className="text-center">
              <a href="/apply" target="blank" className="btn-black">
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faculty;