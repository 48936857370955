import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import logo from "../../assets/img/logo.png";

import "./Navbar.css";

function CustomNavBar() {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  const handleMobileNavClick = (href) => {
    setCollapsed(!collapsed);
    window.location.href = window.location.origin + "/" + href;
  };

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center justify-content-between">
        <NavbarBrand href="/" className="logo">
          <img src={logo} alt="logo" className="img-fluid" />
        </NavbarBrand>
        <Navbar id="navbar" className="navbar navbar-dark" expand="md">
          <NavbarToggler onClick={toggleNavbar} className="me-2" />
          <Collapse isOpen={!collapsed} navbar>
            <Nav className="mr-auto" navbar={true}>
              <NavItem>
                <NavLink href="#about">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#curriculum">Curriculum</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#services">Why Apply</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#faculty">Faculty</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#testimonial">Testimonials</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#faq">FAQ</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="btn-orange nav-button"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const url = window.location.origin + "/apply";
                    window.open(url, "_blank");
                  }}
                >
                  <span className="text-black">Apply Now</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      <div className="container d-flex align-items-center justify-content-start">
        <Navbar
          id="navbar"
          className="navbar navbar-dark mobile-nav"
          expand="md"
        >
          <Nav
            className={`mr-auto ${collapsed ? "d-none" : "d-block"}`}
            navbar={true}
          >
            <NavItem>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  handleMobileNavClick("#about");
                }}
                href="#about"
              >
                About
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  handleMobileNavClick("#curriculum");
                }}
                href="#curriculum"
              >
                Curriculum
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  handleMobileNavClick("#services");
                }}
                href="#services"
              >
                Why Apply
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  handleMobileNavClick("#faculty");
                }}
                href="#faculty"
              >
                Faculty
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  handleMobileNavClick("#testimonial");
                }}
                href="#testimonial"
              >
                Testimonials
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={(e) => {
                  e.preventDefault();
                  handleMobileNavClick("#faq");
                }}
                href="#faq"
              >
                FAQ
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className="btn-orange px-3 nav-button"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setCollapsed(!collapsed);
                  const url = window.location.origin + "/apply";
                  window.open(url, "_blank");
                }}
              >
                <span className="text-black">Apply Now</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    </header>
  );
}

export default CustomNavBar;