import { useAtom } from "jotai";
import { formdataAtom } from "../../lib/atom";

const Range = ({ label, name, min, max, step, value, onChangeFunc }) => {
  const [formdata, setFormdata] = useAtom(formdataAtom);
  return (
    <>
      <div className="d-flex">
        <span className="me-2 fw-bold">1 </span>
        <input
          type="range"
          name={name}
          className="form-range"
          id={name}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e) => setFormdata({ ...formdata, [name]: e.target.value })}
        />
        <span className="ms-2 fw-bold"> 10</span>
      </div>
    </>
  );
};

export default Range;
