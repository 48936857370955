export const employmentStatusValues = [
    {
      value: "I am unemployed and actively looking for a job",
      label: "I am unemployed and actively looking for a job",
    },
    {
      value: "I am employed but looking for another job",
      label: "I am employed but looking for another job",
    },
    {
      value: "I am  a freelancer/self-employed looking for employment",
      label: "I am  a freelancer/self-employed looking for employment",
    },
    {
      value: "I am currently serving (NYSC)",
      label: "I am currently serving (NYSC)",
    },
  ];

  export const unemployedDurationValues = [
    {
      value: "1-6 months",
      label: "1-6 months",
    },
    {
      value: "6-12 months",
      label: "6-12 months",
    },
    {
      value: "More than one year",
      label: "More than one year",
    },
  ];

  export const workExperienceValues = [
    {
      value: "I don’t have any work experience",
      label: "I don’t have any work experience",
    },
    { value: "Between  1 and 4 years", label: "Between  1 and 4 years" },
    { value: "Between 4 and 8 years", label: "Between 4 and 8 years" },
    { value: "Over 8 years", label: "Over 8 years" },
  ];