import React, { useEffect, useState } from "react";
import {
  progressAtom,
  formdataAtom,
  sharedErrorsAtom,
} from "../../../lib/atom";
import { useAtom } from "jotai";
import Radio from "../../Form/Radio";
import Range from "../../Form/Range";
import { useNavigate } from "react-router-dom";
import TextArea from "../../Form/TextArea";
import { useUpdateEmployment } from "../../../hooks/useUpdateEmployment";
import {
  employmentStatusValues,
  unemployedDurationValues,
  workExperienceValues,
} from "../../../constants/employmentConstants"; // Ensure these constants are imported
import { useFormValidation } from "../../../hooks/useFormValidation";
import SaveAndContinueWithBackButton from "../../Utility/SaveAndContinueWithBackButton";

const Employment = () => {
  const router = useNavigate();

  const [, setProgress] = useAtom(progressAtom);
  const [formdata] = useAtom(formdataAtom);
  const { validate } = useFormValidation("employment");
  const { updateEmploymentToDatabase } = useUpdateEmployment();
  const [errors, setErrors] = useState([]);
  const [sharedErrors] = useAtom(sharedErrorsAtom);

  useEffect(() => {
    setProgress(5);
  }, []);

  const handleEmployment = async () => {
    // Validate the component fields
    const errs = await validate(); // Make sure to await this call
    if (errs.length) {
      setErrors(errs);
      return;
    }

    await updateEmploymentToDatabase(); // Make sure this returns a promise
  };

  return (
    <div className="col-md-8 slide-col" id="employment">
      <h4 className="text-start text-uppercase fw-bold">EMPLOYMENT STATUS</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-4">
          <label
            htmlFor="employment_status"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            What is your current employment status?
          </label>

          <Radio
            items={employmentStatusValues}
            name={"employment_status"}
            value={formdata.employment_status}
            flex={false}
          />
        </div>

        {formdata.employment_status ===
          "I am unemployed and actively looking for a job" && (
          <div className="mb-4" id="unemployedDiv">
            <label
              htmlFor="unemployedInput1"
              className="form-label"
              style={{ color: "black", fontWeight: "bold" }}
            >
              How long have you been out of a job? (Pick only one option).
            </label>

            <Radio
              items={unemployedDurationValues}
              name={"unemployment_duration"}
              value={formdata.unemployment_duration}
              flex={false}
            />
          </div>
        )}

        {formdata.employment_status ===
          "I am employed but looking for another job" && (
          <div className="mb-4" id="freelancerDiv">
            <TextArea
              label={"Why do you want to leave where you are now?"}
              name={"reason_for_leaving"}
              value={formdata.reason_for_leaving}
            />
          </div>
        )}

        {formdata.employment_status ===
          "I am employed but looking for another job" && (
          <div className="mb-4" id="">
            <TextArea
              label={"When are you looking to leave?"}
              name={"exit_timeline"}
              value={formdata.exit_timeline}
            />
          </div>
        )}

        {formdata.employment_status === "I am currently serving (NYSC)" && (
          <div className="mb-4" id="freelancerDiv">
            <TextArea
              label={"When will you be done serving? (Short answer text)"}
              name={"service_completion_date"}
              value={formdata.service_completion_date}
            />
          </div>
        )}

        <div className="mb-4">
          <label
            htmlFor="employmentStatus1"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            How many years of work experience do you have? (Pick only one
            option).
          </label>

          <Radio
            items={workExperienceValues}
            name={"work_experience_years"}
            value={formdata.work_experience_years}
            flex={false}
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="job_urgency"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            On a scale of 1-10, how badly do you need a job? Drag the button as
            needed.
            <span className="d-block">
              (1 - 4 = I don’t really need a job. 5 - 7 = I need a job, 8 - 10 =
              I desperately need a job)
            </span>
          </label>
          <Range
            name={"job_urgency"}
            min={"1"}
            max={"10"}
            step={"1"}
            value={formdata.job_urgency}
          />
        </div>

        <div className="mb-3 form-check text-end">
          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger text-start">
              {error}
            </div>
          ))}

          {sharedErrors.map((error, index) => (
            <div key={index} className="alert alert-danger text-start">
              {error}
            </div>
          ))}

          <SaveAndContinueWithBackButton
            handleComms={handleEmployment}
            action={"Save and Continue"}
          />
        </div>
      </form>
    </div>
  );
};

export default Employment;
