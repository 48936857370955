import { useAtom } from "jotai";
import { formdataAtom } from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useUpdateFinancialAid = () => {
  const navigate = useNavigate();
  const [formdata] = useAtom(formdataAtom);

  const updateFinancialAid = async () => {
    Swal.fire({
      title: "Please confirm your answers",
      text: "You will not be able to change it after this. Continue?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const fd = {
          email: formdata.email,
          misc: {
            column_name: "Financial Info",
            payment_option: "Financial Aid",
            data: {
              phone_type: formdata.typeOfPhone,
              has_laptop: formdata.haveLaptop,
              parents_status: formdata.parentAlive,
              employment_status_duration: formdata.employed,
              affordable_amount: formdata.affordableFee,
              bootcamp_attendance_reason: formdata.whyBootcamp,
              additional_info: formdata.anythingElse,
            },
          },
        };

        // const apiUrl = `https://credentials.naomilucas.xyz/api/bootcamp/update/${email}`;
        const apiUrl = process.env.REACT_APP_BASE_URL + `/application/misc`;

        try {
          const res = await axios.post(apiUrl, fd);
          console.log("Financial aid application submitted", res);
          navigate("/apply/success");
        } catch (error) {
          Swal.fire({
            title: "Error",
            text:
              error?.response?.data?.message || "An error occurred. Try again",
            icon: "error",
            confirmButtonColor: "#ECA239",
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Application Submitted!",
          text: "Your application has been successfully submitted.",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#ECA239",
        }).then(() => {
          navigate("/"); // Redirect to home page
        });
      }
    });
  };

  return { updateFinancialAid };
};