import { useAtom } from "jotai";
import { formdataAtom, isUpdatingAtom, sharedErrorsAtom } from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react"; // Import useState for managing loading and submission states
import { calculateOverallEligibility } from "../components/Utility/calculateOverallEligibilty"; // Ensure this function is correctly imported

export const useUpdateDatabase = () => {
  const router = useNavigate();
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const [, setIsUpdate] = useAtom(isUpdatingAtom);
  const [, setSharedErrors] = useAtom(sharedErrorsAtom);

  // Define loading and submission state variables
  const [, setIsLoading] = useState(false);

  const updateDatabase = async () => {
    Swal.fire({
      title: "Please confirm your answers",
      text: "You will not be able to change it after this. Continue?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        // Calculate overall eligibility
        const overallEligibility = calculateOverallEligibility(formdata);

        const email = formdata.email;

        const fd = {
          eligibility: {
            openness_to_tools: formdata.openness_to_tools,
            work_disruption_feelings: formdata.work_disruption_feelings,
            feedback_handling: formdata.feedback_handling,
            approach_to_new_tasks: formdata.approach_to_new_tasks,
            education_focus: formdata.education_focus,
            highest_qualification: formdata.highest_qualification,
          },
          eligible: overallEligibility,
          email: email,
        };

        setFormdata({ ...formdata, eligibilityStatus: overallEligibility });

        const apiUrl =
          process.env.REACT_APP_BASE_URL + `/application/eligibility`;
        // const apiUrl = `https://credentials.naomilucas.xyz/api/bootcamp/update/${email}`;

        try {
          const res = await axios.post(apiUrl, fd);
          setIsLoading(false);
          setIsUpdate(true);
          // console.log(res);
          // if (overallEligibility === "eligible") {
          //   router("/apply/employment");
          // }
        } catch (error) {
          console.log("ERRR::", error);

          const msg =
            error?.message ||
            error?.response?.data?.message ||
            "An error occured. Try again";

          setSharedErrors((prev) => [...prev, msg]);

          const errors = error?.response?.data?.data?.error;

          if (errors) {
            Object.keys(errors).forEach((key) => {
              const messages = errors[key]; // Array of messages
              messages.forEach((message) => {
                console.log(message);
                setSharedErrors((prev) => [...prev, message]);
              });
            });
          }
          return Swal.close();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    // .then((result) => {
    //   if (result.isConfirmed) {
    //     Swal.fire({
    //       title: "Almost there!",
    //       text: "You are almost done with your application",
    //       icon: "success",
    //       confirmButtonText: "Continue",
    //       confirmButtonColor: "#ECA239",
    //       preConfirm: async () => {
    //         router("/apply/employment");
    //       },
    //     });
    //   }
    // });
  };

  return { updateDatabase };
};
